import {format} from 'date-fns';
export const SECRET_KEY = 'super secret key';
export const KEY_DATE_FORMAT = 'yyyy-MM-dd--HH:mm'

export function dateKey(date: Date): string {
    return format(date, KEY_DATE_FORMAT);
}
export async function digestMessage(message: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(message);
    const hashBuffer =  await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
}
export async function token(message: string, secret: string): Promise<string> {
    return await digestMessage(message + secret);
}

export async function currentToken(secret: string): Promise<string> {
    return await token(dateKey(new Date()), secret);
}
