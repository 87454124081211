<template>
  <button class="activator" @click="onButtonClick()">
    {{ buttonText }}
  </button>
  <transition name="slide-fade">
    <div class="modal" :class="{ show }" :key="show" v-if="show">
      <div class="exit-button" @click="onCloseModal()">
        X
      </div>
      <div class="content">
        <slot :onCloseModal="onCloseModal">
          ~
        </slot>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "AppModal",
  props: {
    buttonText: {
      type: String,
      required: true,
    },
  },
  setup() {
    const show = ref<boolean>(false);
    const onButtonClick = () => {
      show.value = true;
    };

    const onCloseModal = () => {
      show.value = false;
    };
    return {
      show,
      onButtonClick,
      onCloseModal,
    };
  },
});
</script>

<style scoped lang="scss">
@import "@/styles/main";

$width: 90vw;
$height: 70vh;

$button-size: 2rem;

button.activator {
  @include app-button;
  font-weight: 700;
}
.modal {
    @include modal(false);
    &.show {
        @include modal(true);
    }
}
</style>
