
import { defineComponent, computed } from "vue";
import { useOrdersManagement } from "@/mixins/order-tracking";
import { useInternalTokenSetup, COMPONENT_TOKEN_PROPS } from "@/mixins/token";
import { ROUTE_NAMES } from "@/router";
import { kitchenAuth } from "@/auth";
export default defineComponent({
  name: "KitchenOrderManagementView",
  components: {},
  props: {
    ...COMPONENT_TOKEN_PROPS,
  },
  setup(props) {
    return {
      ...useOrdersManagement(kitchenAuth),
      ...useInternalTokenSetup(
        computed(() => props.token),
        ROUTE_NAMES["KitchenOrderManagementView"]
      ),
    };
  },
});
