
import { defineComponent } from "vue";
import { Order } from "../../models";

export default defineComponent({
  name: "OrderStatusNumberRow",
  props: {
    orders: {
      type: Array as () => Array<Order>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {};
  },
});
