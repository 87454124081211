import { slice, join } from "lodash";

// APP SETUP
// ---------
type AppMode = "development" | "production";
const APP_MODE = process.env.NODE_ENV as "development" | "production";

// QR CODE
// -------
export const QR_CODE_REFRESH_INTERVAL_MS = 5 * 1000;
export const HASH_CODE_LENGTH = 4;

// BACKEND
// -------
const DEVELOPMENT_BACKEND_SERVER = "http://localhost:8000/";
const PRODUCTION_BACKEND_SERVER = "https://zajedz.pieprzyk.it/";
const BACKEND_ADDRESS_MAPPING: { [key in AppMode]: string } = {
  development: DEVELOPMENT_BACKEND_SERVER,
  production: PRODUCTION_BACKEND_SERVER,
};
export const BACKEND_ADDRESS = BACKEND_ADDRESS_MAPPING[APP_MODE];
export const FRONTEND_ADDRESS = (() => {
  const full =
    location.protocol +
    "//" +
    location.hostname +
    (location.port ? ":" + location.port : "");
  return (
    (full.endsWith("/") ? join(full.slice(0, full.length - 1)) : full) + "/#/"
  );
})();

export const QR_SCANNED_ROOT = "";
export const QR_SCANNED_PATH = QR_SCANNED_ROOT + ":initialHashEnding";
export const QR_SCANNED_ADDRESS = FRONTEND_ADDRESS + QR_SCANNED_ROOT;

if (APP_MODE !== "production") {
  console.warn({
    APP_MODE,
    BACKEND_ADDRESS,
    FRONTEND_ADDRESS,
    QR_SCANNED_ADDRESS,
  });
}
