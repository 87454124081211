
import {defineComponent, computed} from 'vue';

export default defineComponent({
    name: 'AppImage',
    props: {
        src: {
            type: String,
            required: true,
        }
    },
    setup(props) {
        const style = computed(() => ({
            'background-image': `url(${props.src})`
        }));
        return {
            style,
        };
    },
});
