<template>
  <transition-group tag="div" class="OrderStatusNumberRow" name="slide-fade">
    <h2>{{ title }}</h2>
    <div
      class="order-number slide"
      v-for="order of orders"
      :key="order.raw.uuid"
      :class="{ [order.status]: true }"
    >
      {{ order.raw.order_number }}
    </div>
  </transition-group>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Order } from "../../models";

export default defineComponent({
  name: "OrderStatusNumberRow",
  props: {
    orders: {
      type: Array as () => Array<Order>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {};
  },
});
</script>

<style scoped lang="scss">
@import "@/styles/main";
$circle-size: 12vw;
.OrderStatusNumberRow {
  @include grid-center-padding(1rem);
  align-items: start;
  grid-template-columns: repeat(auto-fit, minmax($circle-size, 1fr));
  grid-template-rows: 6vh;
  grid-auto-rows: min-content;

  grid-gap: 2vw;
  h2 {
    @include grid-center;
    grid-column: 1 / -1;
    padding: unset;
    margin: unset;
    font-size: 3rem;
  }
  .order-number {
    @include status-circle($circle-size);
    border-color: lightgray;
  }
}
</style>
