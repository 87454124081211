
import { defineComponent } from "vue";
// import BlobLarge from "@/assets/graphics/zajedz-blob-large.svg";
import ZajedzLogo from "@/assets/graphics/zajedz-logo.svg";
export default defineComponent({
  name: "Navbar",
  components: {
    // BlobLarge,
    ZajedzLogo,
  },
  props: {},
  setup() {
    return {};
  },
});
