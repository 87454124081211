<template>
  <div class="MenuItemPizzaHut">
    <app-image class="image-container" :src="menuItem.raw.image" />

    <div class="name">
      {{ menuItem.raw.name }}
    </div>

    <div class="description">
      {{ menuItem.raw.description }}
    </div>

    <div class="price">{{ menuItem.raw.net_price }} zł</div>

    <app-modal
      class="add-to-cart"
      buttonText="DODAJ"
      v-slot:default="slotProps"
    >
      <order-item-builder
        :menuItem="menuItem"
        @cancel="
          slotProps.onCloseModal();
          cancel();
        "
        @add-to-cart="
          $emit('add-to-cart', $event);
          slotProps.onCloseModal();
        "
      />
    </app-modal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { MenuItem } from "../../models";
import { useOrderItemBuilder } from "@/mixins/menu-item";
import AppImage from "@/components/AppImage.vue";
import AppModal from "@/components/AppModal.vue";
import OrderItemBuilder from "@/components/menu/OrderItemBuilder.vue";
export default defineComponent({
  name: "MenuItemPizzaHut",
  emits: ["add-to-cart", "cancel"],
  components: {
    AppImage,
    AppModal,
    OrderItemBuilder,
  },
  props: {
    menuItem: {
      type: Object as () => MenuItem,
      required: true,
    },
  },
  setup(props, context) {
    return {
      ...useOrderItemBuilder(props.menuItem, context),
    };
  },
});
</script>

<style scoped lang="scss">
@import "@/styles/main";

.MenuItemPizzaHut {
  @include grid-center;
  @include app-card-dark;
  grid-template-columns: 1.5fr 1fr 2fr;
  grid-template-rows: 1fr 1fr 1fr;
  & > * {
    @include grid-center-padding(0.8rem);
    justify-self: start;
    justify-content: start;
  }
  .image-container {
    grid-row: 1 / -1;
    border-radius: 0.3rem;
  }

  .name {
    text-transform: uppercase;
    font-weight: 700;
    grid-column: span 2;
    padding-bottom: 0;
  }

  .description {
    padding-top: 0;
    color: $lighter-color;
    font-size: 60%;
    grid-column: span 2;
    border-bottom: 1px solid $border-color;
    text-align: start;
    align-self: start;
  }

  .price {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 73%;
    white-space: nowrap;
    font-stretch: 50%;
  }
}
</style>
