
import { defineComponent } from "vue";
import { OrderItem } from "../../models";

export default defineComponent({
  name: "OrderItemStatusBox",
  props: {
    orderItem: {
      type: Object as () => OrderItem,
      required: true,
    },
  },
  setup() {
    return {};
  },
});
