<template>
    <div class="SomethingWentWrong">
        <p>
            Coś poszło nietak, prosimy o ponowne zeskanowanie kodu.
        </p>
        <p>
            {{error ? `(${error})` : ''}}
        </p>
    </div>
</template>


<script lang="ts">
import {defineComponent, onMounted} from 'vue';
import router from '@/router';
export default defineComponent({
    name: 'SomethingWentWrong',
    props: {
        error: {
            type: String,
            default: '',
        }
    },
    setup() {
        onMounted(() => {
            setTimeout(() => {
                router.go(-3);
            }, 2400)
        })
        return {};
    },
});
</script>

<style scoped lang="scss">
.SomethingWentWrong {
    
}
</style>
