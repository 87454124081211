import { ExtensionChoiceRaw, ExtensionRaw } from "@/models";
import { SetupContext } from "vue";
import { filter, includes, map } from "lodash";

export const CHOICE_ICON_PROPS = {
    option: {
        type: Object as () => ExtensionRaw,
        required: true,
    },
    selected: {
        type: Boolean,
        default: false,
        required: true,
    },
};

export const CHOICE_PROPS = {
  choice: {
    type: Array as () => Array<ExtensionChoiceRaw>,
    required: true,
  },
  extension: {
    type: Object as () => ExtensionRaw,
    required: true,
  },
};
export type ChoiceProps = Readonly<{
  choice: ExtensionChoiceRaw[];
  extension: ExtensionRaw;
}>;
export function useSingleChoice(
  props: ChoiceProps,
    context: SetupContext<("update:choice")[]>
) {
  const isSelected = (option: ExtensionChoiceRaw): boolean =>
    includes(
      map(props.choice, (c) => c.id),
      option.id
    );

  const optionClicked = (option: ExtensionChoiceRaw) => {
    isSelected(option)
      ? context.emit(
          "update:choice",
          filter(props.choice, (o) => o.id !== option.id)
        )
      : context.emit("update:choice", [option]);
  };

  return { isSelected, optionClicked };
}

export function useMultipleChoice(
  props: ChoiceProps,
    context: SetupContext<("update:choice")[]>
) {
  const { isSelected } = useSingleChoice(props, context);
  const optionClicked = (option: ExtensionChoiceRaw) => {
    isSelected(option)
      ? context.emit(
          "update:choice",
          filter(props.choice, (o) => o.id !== option.id)
        )
      : context.emit("update:choice", [...props.choice, option]);
  };
  return {
    isSelected,
    optionClicked,
  };
}
