
import { defineComponent, computed } from "vue";
import { useOrdersManagement } from "@/mixins/order-tracking";
import { ROUTE_NAMES } from "@/router";
import { useInternalTokenSetup, COMPONENT_TOKEN_PROPS } from "@/mixins/token";
import OrderStatusNumberRow from "@/components/shop/OrderStatusNumberRow.vue";
import { kitchenAuth } from "@/auth";
export default defineComponent({
  name: "ShopOrderTrackingView",
  components: {
    OrderStatusNumberRow,
  },
  props: {
    ...COMPONENT_TOKEN_PROPS,
  },
  setup(props) {
    const { ...restOrdersManagement } = useOrdersManagement(kitchenAuth);
    return {
      ...restOrdersManagement,
      ...useInternalTokenSetup(
        computed(() => props.token),
        ROUTE_NAMES["ShopOrderTrackingView"]
      ),
    };
  },
});
