import { ref, onMounted, computed } from "vue";
import {
  Order,
  OrderItem,
  OrderItemStatus,
  ORDER_ITEM_STATUS_HUMAN_NAME_MAPPING,
  ORDER_ITEM_STATUS_AVAILABLE_KITCHEN,
} from "@/models";
import { useLoading } from "@/mixins/common";
import { getOrderStatus, getOrders, updateOrderItem } from "@/requests";
import { useInterval } from "@/mixins/common";
import { sortBy, reverse, filter } from "lodash";
import { AuthToken } from "@/auth";

export function useOrderTracking(orderUuid: string, auth: AuthToken) {
  const { loading, withLoading } = useLoading();
  const order = ref<Order | null>(null);
  const getOrder = () =>
    withLoading(async () => {
      order.value = await getOrderStatus(orderUuid, auth);
    });
  onMounted(getOrder);
  return {
    order,
    getOrder,
    orderLoading: loading,
    ORDER_ITEM_STATUS_HUMAN_NAME_MAPPING,
    ...useInterval(getOrder, 5000),
  };
}

export function useOrdersManagement(auth: AuthToken) {
  const ordersRaw = ref<Order[]>([]);
  const { loading, withLoading } = useLoading();
  const getData = () =>
    withLoading(async () => {
      ordersRaw.value = await getOrders(auth);
    });
  const orders = computed((): Order[] =>
    reverse(
      sortBy(
        filter(ordersRaw.value, (o) => o.status !== OrderItemStatus.Received),
        (o) => o.raw.created
      ) as Order[]
    )
  );
  const doneOrders = computed((): Order[] =>
    filter(orders.value, (order) => order.anyDone)
  );
  const acceptedOrders = computed((): Order[] =>
    filter(orders.value, (order) => order.status === OrderItemStatus.Accepted)
  );

  async function setStatus(orderItem: OrderItem, status: OrderItemStatus) {
    withLoading(async () => {
      await updateOrderItem(
        orderItem.raw.id,
        {
          status,
        },
        auth
      );
      await getData();
    });
  }

  return {
    orders,
    doneOrders,
    acceptedOrders,
    ordersLoading: loading,
    getData,
    setStatus,
    ORDER_ITEM_STATUS_AVAILABLE_KITCHEN,
    ...useInterval(getData, 5000),
  };
}
