<template>
  <div class="home">
    <menu-item-pizza-hut
      v-for="(menuItem, index) of menuItems"
      :key="menuItem.raw.id"
      :menuItem="menuItem"
      :zIndex="menuItems.length - index"
      @add-to-cart="addToCart($event)"
    ></menu-item-pizza-hut>
    <transition name="slide-fade">
      <the-cart
        class="cart"
        v-if="readyToOrder"
        :cartItems="cartItems"
        :totalPrice="totalPrice"
        @place-order="placeOrder"
        @cancel="cancel"
        @remove-item="removeItem($event)"
      />
    </transition>
  </div>
</template>
2
<script lang="ts">
import { defineComponent } from "vue";
import { useMenuItems } from "@/mixins/menu-item";
import { useCart } from "@/mixins/cart";
import { userAuth } from "@/auth";
import MenuItemPizzaHut from "@/components/menu/MenuItemPizzaHut.vue";
import TheCart from "@/components/menu/TheCart.vue";
export default defineComponent({
  name: "Home",
  components: {
    MenuItemPizzaHut,
    TheCart,
  },
  setup() {
    return {
      ...useMenuItems(userAuth),
      ...useCart(userAuth),
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/styles/main";
.home {
  @include grid-center-padding(0.7rem);
    grid-auto-rows: 10rem;
    grid-gap: 0.8rem;
}
</style>
