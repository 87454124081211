<template>
    <div class="QrCodeView">
        <!-- <p>
             secret: {{secret}}
             </p>
             <p>
             hash: {{hash}}
             </p>
             <a v-if="link !== null" :href="link">
             {{link}}
             </a> -->
        <canvas id="qr-image" :ref="el => { canvas = el }" width="100px" height="200px">
        </canvas>
        <div class="link">
            <a :href="link" style="color: white;"><h1>{{link}}</h1></a>
        </div>

        <div class="code">
            {{code}}
        </div>
    </div>
</template>


<script lang="ts">
import {defineComponent, ref} from 'vue';
import {useHashGeneration} from '@/mixins/qrcode';
import { QR_CODE_REFRESH_INTERVAL_MS } from '@/config';
export default defineComponent({
    name: 'QrCodeView',
    props: {
        secret: {
            type: String,
            required: true,
        }
    },
    setup(props) {
        const canvas = ref<HTMLCanvasElement | null>(null);
        return {
            ...useHashGeneration(QR_CODE_REFRESH_INTERVAL_MS, props.secret, canvas, 1000),
            canvas,
        };
    },
});
</script>

<style lang="scss">
@import '@/styles/main';

.QrCodeView {
    z-index: 1000;
    @include grid-center;
    position: absolute;
    top: 0;
    left: 0;
    grid-template-columns: 1fr 1fr;
    .qr-image {
        grid-column: 1 / -1;
        width: 100%;
        height: 100%;
    }
    background-image: url("/img/qr-code-view.png");
    background-color: #262627;
    background-repeat: no-repeat;
    background-size: contain;
    #qr-image {
        position: absolute;
        top: 13vh;
        right: 6.5vw;
    }

    .link {
        position: absolute;
        bottom: 7vh;
        font-size: 3.5rem;
        background-color: $table-dark-color;
        width: 100%;
        & a {
            text-decoration: none;
        }
    }

    .code {
        font-size: 12rem;
        position: absolute;
        right: 17vw;
        color: $table-dark-color;
        bottom: 30.8vh;
        font-weight: 700;
    }
}
</style>
