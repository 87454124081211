
import {defineComponent, ref} from 'vue';
import {useHashGeneration} from '@/mixins/qrcode';
import { QR_CODE_REFRESH_INTERVAL_MS } from '@/config';
export default defineComponent({
    name: 'QrCodeView',
    props: {
        secret: {
            type: String,
            required: true,
        }
    },
    setup(props) {
        const canvas = ref<HTMLCanvasElement | null>(null);
        return {
            ...useHashGeneration(QR_CODE_REFRESH_INTERVAL_MS, props.secret, canvas, 1000),
            canvas,
        };
    },
});
