<template>
    <div class="AppImage" :style="style">
        
    </div>
</template>


<script lang="ts">
import {defineComponent, computed} from 'vue';

export default defineComponent({
    name: 'AppImage',
    props: {
        src: {
            type: String,
            required: true,
        }
    },
    setup(props) {
        const style = computed(() => ({
            'background-image': `url(${props.src})`
        }));
        return {
            style,
        };
    },
});
</script>

<style scoped lang="scss">
@import '@/styles/main';
.AppImage {
    @include grid-center;
    background-size: cover;
    background-position: center center;
}
</style>
