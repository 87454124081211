
import { defineComponent } from "vue";
import { MenuItem } from "../../models";
import { useOrderItemBuilder } from "@/mixins/menu-item";
import AppImage from "@/components/AppImage.vue";
import AppModal from "@/components/AppModal.vue";
import OrderItemBuilder from "@/components/menu/OrderItemBuilder.vue";
export default defineComponent({
  name: "MenuItemPizzaHut",
  emits: ["add-to-cart", "cancel"],
  components: {
    AppImage,
    AppModal,
    OrderItemBuilder,
  },
  props: {
    menuItem: {
      type: Object as () => MenuItem,
      required: true,
    },
  },
  setup(props, context) {
    return {
      ...useOrderItemBuilder(props.menuItem, context),
    };
  },
});
