<template>
  <div class="ExtensionSelector">
    <label :for="extension.name" class="selection-name">
      {{ extension.name }}
    </label>
    <div class="select-wrapper">
      <div
        class="choice"
        v-for="choice of extension.choices"
        :key="choice.id"
        :class="{ deselected: !isSelected(choice) }"
        @click="optionClicked(choice)"
      >
        {{ choice.text }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  CHOICE_PROPS,
  useSingleChoice,
  useMultipleChoice,
  ChoiceProps,
} from "@/mixins/choice";

export default defineComponent({
  name: "ExtensionSelector",
  emits: ["update:choice"],
  props: {
    ...CHOICE_PROPS,
    multiple: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, context) {
    const { ...rest } = props.multiple
      ? useMultipleChoice(props as ChoiceProps, context)
      : useSingleChoice(props as ChoiceProps, context);
    return {
      ...rest,
    };
  },
});
</script>

<style scoped lang="scss">
@import "@/styles/main";
$selector-height: 2rem;
$title-height: $selector-height * 0.23;
$offset-right: $title-height / 2;
@mixin select-card {
  border: 2px solid rgba($lighter-color, 0.1);
  border-radius: 0.5rem;
}
.ExtensionSelector {
  @include grid-center-padding(0.5rem);
  grid-template-rows: $title-height 1fr;
  border: 2px solid $lighter-color;
  border-radius: 0.5rem;
  height: $selector-height;
  @include select-card;

  .selection-name {
    font-size: 0.7rem;
    justify-self: start;
    transform: translate3d($offset-right * 3, -$selector-height * 0.6, 0);
    background-color: $card-lighter-color;
    padding: $offset-right;
    width: 4rem;
    height: $title-height;
    font-style: italic;
  }

    .select-wrapper {
        @include grid-center;
        grid-gap: 0.45rem;
        .choice {
            @include menu-choice-button;
            &.deselected {
                @include menu-choice-button(true);
            }
        }
    }
}
</style>
