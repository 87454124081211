<template>
  <transition name="slide-fade">
    <div
      class="TheCart"
      :class="{ clicked, unclicked: !clicked }"
      v-if="clicked"
    >
      <div class="header-container">
        <x-circle class="hide-cart" @click="clicked = false" />
        <h3>Twoje zamówienie</h3>
      </div>

      <transition-group tag="div" name="slide-fade" class="order-items content">
        <div class="order-item" v-for="(item, index) of cartItems" :key="index">
          <x-circle class="remove" @click="removeItem(index)" />
          <div class="name">{{ item.menu_item.name }}</div>
          <div class="description">{{ itemDescription(item) }}</div>
          <div class="price">{{ item.menu_item.net_price }} zł</div>
        </div>
      </transition-group>

      <div class="order-summary">
        <div class="summary-item">
          <x-circle class="remove" @click="cancel()" />
          <div class="name">
            razem
          </div>
          <div class="price">{{ totalPrice }} zł</div>
        </div>
      </div>

      <div class="order-buttons">
        <button class="pay-onsite" @click="payOnSite()">
          Zapłać przy kasie
        </button>
        <button class="pay-online disabled">Zapłać online</button>
      </div>
    </div>

    <div
      class="TheCart"
      :class="{ clicked, unclicked: !clicked }"
      v-else
      @click="clicked = true"
    >
      <shopping-bag class="shopping-bag" />
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import ShoppingBag from "@/components/icons/ShoppingBag.vue";
import XCircle from "@/components/icons/XCircle.vue";
import { OrderItemRaw } from "@/models";

export default defineComponent({
  name: "TheCart",
  components: { ShoppingBag, XCircle },
  emits: ["cancel", "place-order", "remove-item"],
  props: {
    cartItems: {
      type: Array as () => OrderItemRaw[],
      required: true,
    },
    totalPrice: {
      type: Number,
      required: true,
    },
  },
  setup(props, context) {
    const clicked = ref<boolean>(false);
    const itemDescription = (item: OrderItemRaw): string =>
      `${[
        item.exclusive_extensions.map((e) => e.text).join(", "),
        item.multiple_choice_extensions.map((e) => e.text).join(", "),
      ].join(", ")}`;

    const removeItem = (index: number) => {
      context.emit("remove-item", index);
    };

    const payOnSite = () => context.emit("place-order");
    const cancel = () => context.emit("cancel");
    return { clicked, itemDescription, removeItem, payOnSite, cancel };
  },
});
</script>

<style scoped lang="scss">
h3 {
  margin: 0;
}
@import "@/styles/main";
$circle-size: 4.5rem;
$order-item-height: 4rem;

$column-layout: 0.5fr 1fr 1fr;

@mixin order-item {
  @include grid-center;
  grid-template-columns: $column-layout;
  grid-template-rows: 1fr 1fr;
  height: $order-item-height;
  border-bottom: 2px solid rgba(white, 0.04);

  $font-size: 1.6rem;
  .remove {
    grid-row: span 2;
    height: 2rem;
    color: rgba($app-red, 0.3);
    @include hoverable;
  }
  .name {
    font-weight: 600;
    font-size: $font-size * 0.7;
    grid-column: 2;
  }
  .description {
    font-weight: 600;
    font-size: $font-size * 0.4;
    grid-column: 2;
  }

  .price {
    font-style: italic;
    grid-column: 3;
    grid-row: 1 / 3;
    font-size: $font-size * 0.6;
  }
}

.TheCart {
  z-index: 400;

  &.unclicked {
    @include app-gradient;
    @include grid-center;
    @include hoverable;
    @include vuetify-shadow;

    transition: all 0.4s ease-out;
    position: absolute;
    bottom: $circle-size/2;
    right: $circle-size/2;
    width: $circle-size;
    height: $circle-size;
    border-radius: 100%;

    .shopping-bag {
      @include icon($circle-size * 0.6);
      transform: translateY(-$circle-size * 0.03);
    }
  }

  &.clicked {
    $width: 90vw;
    @include modal(true);
    top: $navbar-height * 1.4;
    left: (100vw - $width) * 0.5;
    width: $width;
    grid-template-rows:
      1.5fr calc(#{$order-item-height * 5} + 10px) calc(
        #{$order-item-height * 1} + 2px
      )
      1fr;

    .header-container {
      @include grid-center;
      grid-template-columns: $column-layout;

      h3 {
        grid-column: 2 / -1;
        transform: translateX(-1.75rem);
      }

      .hide-cart {
        height: 2rem;
        color: rgba(white, 0.3);
        @include hoverable;
      }
    }
    .order-items {
      grid-auto-rows: min-content;
      overflow-y: scroll;
      .order-item {
        @include order-item;
      }
    }

    .order-summary {
      @include grid-center;
      .summary-item {
        @include order-item;
        grid-template-rows: 1fr;
      }
    }

    .order-buttons {
      @include grid-center;
      grid-template-columns: repeat(2, 1fr);
      button {
        @include app-button;
        @include grid-center;
        font-weight: 700;
        font-size: 0.8rem;
        border-top-right-radius: unset;
        border-top-left-radius: unset;
        &.pay-online {
          border-bottom-left-radius: unset;
        }
        &.pay-onsite {
          border-bottom-right-radius: unset;
        }
      }
    }
  }
}
</style>
