
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "AppModal",
  props: {
    buttonText: {
      type: String,
      required: true,
    },
  },
  setup() {
    const show = ref<boolean>(false);
    const onButtonClick = () => {
      show.value = true;
    };

    const onCloseModal = () => {
      show.value = false;
    };
    return {
      show,
      onButtonClick,
      onCloseModal,
    };
  },
});
