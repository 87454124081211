<template>
  <div class="OrderItemBuilder">
    <h3>{{ menuItem.raw.name }}</h3>

    <extension-selector
      :multiple="false"
      class="extension"
      v-for="extension of menuItem.raw.exclusive_extensions"
      @update:choice="onClickExclusiveExtensionSelection($event)"
      :key="'exclusive-' + extension.id"
      :extension="extension"
      :choice="exclusiveExtensions"
    >
      {{ extension }}
    </extension-selector>

    <extension-selector
      :multiple="true"
      class="extension"
      v-for="extension of menuItem.raw.multiple_choice_extensions"
      @update:choice="onClickMultipleChoiceExtensionSelection($event)"
      :key="'multiple-choice-' + extension.id"
      :extension="extension"
      :choice="multipleChoiceExtensions"
    >
      {{ extension }}
    </extension-selector>

    <button class="ready-button" @click="$emit('add-to-cart', orderItem)">Gotowe</button>
    <button class="cancel-button" @click="cancel()">Anuluj</button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { MenuItem } from "../../models";
import { useOrderItemBuilder } from "@/mixins/menu-item";
import ExtensionSelector from "@/components/menu/ExtensionSelector.vue";

export default defineComponent({
  name: "OrderItemBuilder",
  emits: ["add-to-cart", "cancel"],
  components: { ExtensionSelector },
  props: {
    menuItem: {
      type: Object as () => MenuItem,
      required: true,
    },
  },
  setup(props, context) {
    return {
      ...useOrderItemBuilder(props.menuItem, context),
    };
  },
});
</script>

<style scoped lang="scss">
@import "@/styles/main";
.OrderItemBuilder {
  & * {
    margin: 0;
  }
  @include grid-center-padding(1rem);
  grid-gap: 1.2rem;
  grid-template-rows: min-content;
  grid-auto-rows: max-content;
  & > * {
    @include grid-center-padding(0.5rem);
  }

  .ready-button {
    margin-top: 1rem;
    @include grid-center;
    width: 100%;
    @include menu-choice-button;
  }

  .cancel-button {
      @include menu-choice-button(true);
      transform: translateY(-0.8rem);
  }
}
</style>
