
import { defineComponent } from "vue";
import { MenuItem } from "../../models";
import { useOrderItemBuilder } from "@/mixins/menu-item";
import ExtensionSelector from "@/components/menu/ExtensionSelector.vue";

export default defineComponent({
  name: "OrderItemBuilder",
  emits: ["add-to-cart", "cancel"],
  components: { ExtensionSelector },
  props: {
    menuItem: {
      type: Object as () => MenuItem,
      required: true,
    },
  },
  setup(props, context) {
    return {
      ...useOrderItemBuilder(props.menuItem, context),
    };
  },
});
