<template>
  <div class="MyOrderTrackingView" v-if="order !== null" ref="page">
    <h1 class="title">{{ order.statusHuman }}</h1>
    <div
      class="order-number"
      :class="{
        [order.status]: true,
        highlight: orderLoading,
      }"
    >
      #{{ order.raw.order_number }}
    </div>

    <div class="order-status">
      <order-item-status-box
        v-for="orderItem of order.orderItems"
        :orderItem="orderItem"
        :key="orderItem.id"
      />
    </div>
  </div>
  <div v-else>
    ...
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useOrderTracking } from "@/mixins/order-tracking";
import OrderItemStatusBox from "@/components/order-status/OrderItemStatusBox.vue";
import { kitchenAuth } from "@/auth";

export default defineComponent({
  name: "MyOrderTrackingView",
  components: {
    OrderItemStatusBox,
  },
  props: {
    orderUuid: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const page = ref<HTMLDivElement | null>(null);
    onMounted(() => {
      setTimeout(() => {
        if (page.value == null || page.value.parentElement == null) {
          return;
        }
        page.value.parentElement.scrollBy({
          top: window.innerHeight,
          behavior: "smooth",
        });
      }, 1500);
    });
    return {
      page,
      ...useOrderTracking(props.orderUuid, kitchenAuth),
    };
  },
});
</script>

<style scoped lang="scss">
@import "@/styles/main";
.MyOrderTrackingView {
  @include grid-center;
  grid-template-rows: 30% 70%;
  .order-number {
    @include status-circle(10rem);
    border-color: lightgray;
  }
  .order-status {
    @include grid-center;
    grid-auto-rows: 4rem;
    grid-gap: 0.5rem;
  }
}
</style>
