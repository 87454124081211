import {onMounted, Ref} from 'vue';
import router from '@/router';
import {kitchenAuth} from '@/auth';

export const EMPTY_TOKEN = "ok";

export const COMPONENT_TOKEN_PROPS = {
    token: {
        type: String,
        required: false,
        default: EMPTY_TOKEN,
    },
};

export function useInternalTokenSetup(
  token: Ref<string | null | undefined>,
  routeName: string,
  empty = EMPTY_TOKEN
) {
  onMounted(() => {
    setTimeout(() => {
      if (token.value && token.value !== empty) {
        kitchenAuth.token = token.value;
        router.push({ name: routeName, params: { token: empty } });
      }
    }, 1000);
  });

  return {};
}
