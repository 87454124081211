
import { defineComponent } from "vue";
import {
  CHOICE_PROPS,
  useSingleChoice,
  useMultipleChoice,
  ChoiceProps,
} from "@/mixins/choice";

export default defineComponent({
  name: "ExtensionSelector",
  emits: ["update:choice"],
  props: {
    ...CHOICE_PROPS,
    multiple: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, context) {
    const { ...rest } = props.multiple
      ? useMultipleChoice(props as ChoiceProps, context)
      : useSingleChoice(props as ChoiceProps, context);
    return {
      ...rest,
    };
  },
});
