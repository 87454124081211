import { ref, onMounted, onBeforeUnmount, computed, Ref, watch } from "vue";
import { currentToken } from "@/qrcode";
import { userAuth } from "@/auth";
import { QR_SCANNED_ADDRESS, HASH_CODE_LENGTH } from "@/config";
import { isNil } from "lodash";
import { toCanvas } from "qrcode";
import { getAccessToken, show404 } from "@/requests";
import router from "@/router";
import { sleep } from "./common";

export function generateQrCode(
  canvas: Ref<HTMLCanvasElement | null>,
  text: Ref<string | null>,
  width: number
) {
  const generate = (text: string | null) => {
    if (isNil(text)) return;
    if (isNil(canvas.value)) return;

    toCanvas(canvas.value, text, { width }, (error) => {
      if (error) console.error(error);
    });
  };
  watch(text, generate);
  generate(text.value);
}

export function useHashGeneration(
  intervalMs: number,
  secret: string,
  canvas: Ref<HTMLCanvasElement | null>,
  width: number
) {
  const intervalHandler = ref(-1);
  const hash = ref<string | null>(null);
  const code = computed((): string =>
    isNil(hash.value)
      ? "------------------"
      : hash.value.slice(hash.value.length - HASH_CODE_LENGTH)
  );
  const link = computed((): string | null =>
    isNil(hash.value) ? null : `${QR_SCANNED_ADDRESS}${code.value}`
  );
  onMounted(async () => {
    hash.value = await currentToken(secret);

    generateQrCode(canvas, link, width);
    intervalHandler.value = setInterval(async () => {
      hash.value = await currentToken(secret);
      // eslint-disable-next-line
    }, intervalMs) as any;

    setTimeout(() => {
      generateQrCode(canvas, link, width);
    }, intervalMs + 1);
  });

  onBeforeUnmount(() => {
    clearInterval(intervalHandler.value);
  });

  return {
    hash,
    code,
    link,
  };
}

export function useQRCodeScanned(initialHashEnding: string) {
  const hashEnding = ref<string>(initialHashEnding);
  console.log({ hashEnding: hashEnding.value });
  const isValid = computed(
    (): boolean => hashEnding.value.length === HASH_CODE_LENGTH
  );

  const getData = async () => {
    if (!isValid.value) {
      return;
    }
    userAuth.token = await getAccessToken(hashEnding.value);
    await sleep(200); // authentication is handled in requests.rs
    router.push({ name: "Home" });
  };
  const onNewPin = async (newPin: string) => {
    hashEnding.value = newPin;
    await getData();
  };
  onMounted(async () => {
    await getData();
  });
  return {
    hashEnding,
    isValid,
    getData,
    onNewPin,
  };
}
