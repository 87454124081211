import { map, every, some, keys, sortBy, fromPairs, pick } from "lodash";
import { parseISO, formatDistanceToNow } from "date-fns";
import plLocale from "date-fns/locale/pl";
// DJANGO
// ------
export interface DjangoResponse<T> {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
}

// MENU
// ----
export interface ExtensionChoiceRaw {
  id: number;
  text: string;
}

export interface ExtensionRaw {
  id: number;
  choices: ExtensionChoiceRaw[];
}
export interface MenuItemCategoryRaw {
  id: number;
  created: string;
  modified: string;
  archived: boolean;
  name: string;
}

export interface MenuItemRaw {
  id: number;
  created: string;
  modified: string;
  archived: boolean;
  name: string;
  net_price_currency: string;
  net_price: string;
  description: string;
  image: string | null;
  category: MenuItemCategoryRaw;
  exclusive_extensions: ExtensionRaw[];
  multiple_choice_extensions: ExtensionRaw[];
}

export class MenuItem {
  public constructor(public raw: MenuItemRaw) {}
}

/// ORDERS
/// -----

/// class OrderItemStatus(Enum):
/// INITIATED = 'initiated'
/// ACCEPTED = 'accepted'
/// REJECTED = 'rejected'
/// DONE = 'done' // WARN: update _variables.scss!

export enum OrderItemStatus {
  Accepted = "accepted",
  Done = "done",
  Received = "received",
}

export const ORDER_ITEM_STATUS_HUMAN_NAME_MAPPING: {
  [key in OrderItemStatus]: string;
} = {
  // TODO: translate this
  [OrderItemStatus.Accepted]: "przygotowywane",
  [OrderItemStatus.Done]: "gotowe do odbioru",
  [OrderItemStatus.Received]: "odebrano",
};

export const ORDER_ITEM_STATUS_COLOR_MAPPING: {
  [key in OrderItemStatus]: string;
} = {
  [OrderItemStatus.Accepted]: "gray",
  [OrderItemStatus.Done]: "green",
  [OrderItemStatus.Received]: "lightgray", // WARN: update _variables.scss!
};

export const ORDER_ITEM_STATUS_AVAILABLE_KITCHEN = pick(
  ORDER_ITEM_STATUS_HUMAN_NAME_MAPPING,
  [OrderItemStatus.Done, OrderItemStatus.Received]
);

export const ALL_ORDER_ITEM_STATUSES = keys(ORDER_ITEM_STATUS_COLOR_MAPPING);
export interface OrderItemRaw {
  id: number;
  menu_item: MenuItemRaw;
  exclusive_extensions: ExtensionChoiceRaw[];
  multiple_choice_extensions: ExtensionChoiceRaw[];
  created: string;
  modified: string;
  status: OrderItemStatus;
  status_changed: string;
  is_removed: boolean;
  done_at: string;
  order: string;
}
export type OrderItemForm = Omit<
  OrderItemRaw,
  | "created"
  | "done_at"
  | "id"
  | "is_removed"
  | "modified"
  | "order"
  | "status"
  | "status_changed"
>;
export class OrderItem {
  public constructor(public raw: OrderItemRaw) {}
  public get id(): number {
    return this.raw.id;
  }
  public get name(): string {
    return this.raw.menu_item.name;
  }
  public get status(): OrderItemStatus {
    return this.raw.status;
  }

  public get dateCreated(): Date {
    return parseISO(this.raw.created);
  }

  public get timeHumanSince(): string {
    return formatDistanceToNow(this.dateCreated, {
      locale: plLocale,
      addSuffix: true,
    }); // TODO: make this translate automatically
  }
  public get statusHuman(): string {
    return ORDER_ITEM_STATUS_HUMAN_NAME_MAPPING[this.status];
  }

  public get extensions(): string[] {
    return [
      ...map(this.raw.exclusive_extensions, (e) => e.text),
      ...map(this.raw.multiple_choice_extensions, (e) => e.text),
    ];
  }
}
export interface OrderLocation {
  id: number;
  created: string;
  modified: string;
  is_removed: boolean;
  name: string;
  city: string;
  street: string;
  phone_number: string;
}

export interface OrderDevice {
  id: number;
  location: OrderLocation;
  created: string;
  modified: string;
  is_removed: boolean;
  name: string;
  device_type: string;
}

export interface OrderRaw {
  id: number;
  uuid: string;
  items: OrderItemRaw[];
  device: OrderDevice;
  created: string;
  modified: string;
  is_removed: boolean;
  archived: boolean;
  order_number: number;
}

export class Order {
  public constructor(public raw: OrderRaw) {}
  public get orderItems(): OrderItem[] {
    return sortBy(
      map(this.raw.items, (item) => new OrderItem(item)),
      (i) => i.id
    );
  }
  private get allStatuses(): OrderItemStatus[] {
    return map(this.orderItems, (item) => item.status);
  }
  public get status(): OrderItemStatus {
    if (every(this.allStatuses, (status) => status === OrderItemStatus.Done)) {
      return OrderItemStatus.Done;
    }

    if (
      every(this.allStatuses, (status) => status === OrderItemStatus.Received)
    ) {
      return OrderItemStatus.Received;
    }
    return OrderItemStatus.Accepted;
  }

  public get done(): boolean {
    return this.status === OrderItemStatus.Done;
  }

  public get anyDone(): boolean {
    return some(this.orderItems, (i) => i.status === OrderItemStatus.Done);
  }

  public get accepted(): boolean {
    return this.status === OrderItemStatus.Accepted;
  }

  public get statusHuman(): string {
    return ORDER_ITEM_STATUS_HUMAN_NAME_MAPPING[this.status];
  }
}

export interface OrderForm
  extends Omit<
    OrderRaw,
    | "uuid"
    | "id"
    | "created"
    | "modified"
    | "is_removed"
    | "archived"
    | "items"
    | "device"
    | "order_number"
  > {
  items: Array<
    Omit<
      OrderItemForm,
      "menu_item" | "exclusive_extensions" | "multiple_choice_extensions"
    > & {
      menu_item: number;
      exclusive_extensions: number[];
      multiple_choice_extensions: number[];
    }
  >;
}
