import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Home from "@/views/Home.vue";
import QrCodeView from "@/views/QrCodeView.vue";
import QRCodeScannedView from "@/views/QRCodeScannedView.vue";
import MyOrderTrackingView from "@/views/MyOrderTrackingView.vue";
import SomethingWentWrong from "@/views/SomethingWentWrong.vue";
import KitchenOrderManagementView from "@/views/KitchenOrderManagementView.vue";
import ShopOrderTrackingView from "@/views/ShopOrderTrackingView.vue";

import { QR_SCANNED_PATH } from "@/config";

export const ROUTE_NAMES = {
  Home: "Home",
  MyOrderTrackingView: "order-status",
  QrCodeView: "qr",
  KitchenOrderManagementView: "kitchen-orders",
  ShopOrderTrackingView: "shop-order-tracking",
  SomethingWentWrong: "404",
  QRCodeScannedView: "qr-scanned",
  EmptyQRCodeScannedview: "qr-scanned-empty",
};

export const QR_SCANNED_ROUTE = {
  path: "/" + QR_SCANNED_PATH,
  name: ROUTE_NAMES["QRCodeScannedView"],
  component: QRCodeScannedView,
  props: true,
};

export const EMPTY_QR_SCANNED_ROUTE = {
  path: "/",
  name: ROUTE_NAMES["EmptyQRCodeScannedview"],
  component: QRCodeScannedView,
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/menu",
    name: ROUTE_NAMES["Home"],
    component: Home,
  },
  {
    path: "/status/:orderUuid",
    name: ROUTE_NAMES["MyOrderTrackingView"],
    component: MyOrderTrackingView,
    props: true,
  },
  {
    path: "/qr/b4:secret",
    name: ROUTE_NAMES["QrCodeView"],
    component: QrCodeView,
    props: true,
  },
  {
    path: "/kuchnia/:token",
    name: ROUTE_NAMES["KitchenOrderManagementView"],
    component: KitchenOrderManagementView,
    props: true,
  },
  {
    path: "/lada/:token",
    name: ROUTE_NAMES["ShopOrderTrackingView"],
    component: ShopOrderTrackingView,
    props: true,
  },
  {
    path: "/404",
    name: ROUTE_NAMES["SomethingWentWrong"],
    component: SomethingWentWrong,
    props: true,
  },
  QR_SCANNED_ROUTE,
  EMPTY_QR_SCANNED_ROUTE,
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
