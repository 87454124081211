<template>
  <navbar class="app-navbar app-navbar-controller"></navbar>
  <transition name="slide-fade">
    <router-view class="app-view" />
  </transition>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Navbar from "@/components/Navbar.vue";
export default defineComponent({
  name: "App",
  components: {
    Navbar,
  },
  props: {},
  setup() {
    const hardRefresh = () => {
      navigator.serviceWorker
        .getRegistrations()
        .then((registrations) =>
          Promise.all(registrations.map((r) => r.unregister()))
        )
        .then(() => window.location.reload());
    };
    return {
      hardRefresh,
    };
  },
});
</script>

<style lang="scss">
@import "@/styles/main.scss";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");

html {
  height: 100vh;
}
body {
  margin: 0;
  font-size: 16px;
  height: 100vh;
  width: 100vw;
  background-size: contain;
  background-repeat: unset;
  @include grid-center;
  @include table-background;
  background-image: unset;
}
#app {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  @include grid-center;
  @include table-background;
  height: 100vh;
  width: 100vw;
  @include for-tablet-portrait-up {
    max-width: $max-app-width;
  }

  grid-template-rows: $navbar-height 1fr;
  @include for-tablet-portrait-up {
    grid-template-rows: $large-navbar-height 1fr;
  }
  color: $font-primary;
  overflow-x: hidden;

  .app-navbar {
    position: sticky;
    top: 0;
  }
}

/* #nav {
   padding: 30px;

   a {
   font-weight: bold;
   color: #2c3e50;

   &.router-link-exact-active {
   color: #42b983;
   }
   }
   } */
</style>
