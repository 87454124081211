
import { defineComponent } from "vue";
import { useQRCodeScanned } from "@/mixins/qrcode";
import PinInput from "@/components/qr/PinInput.vue";
import { HASH_CODE_LENGTH } from "@/config";

export default defineComponent({
  name: "QRCodeScannedView",
  components: { PinInput },
  props: {
    initialHashEnding: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    console.log({ props: JSON.stringify(props) });
    return {
      ...useQRCodeScanned(props.initialHashEnding),
      HASH_CODE_LENGTH,
    };
  },
});
