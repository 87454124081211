
import { defineComponent, ref } from "vue";
import { range } from "lodash";

export default defineComponent({
  name: "PinInput",
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    length: {
      type: Number,
      required: true,
    },
  },
  setup(props, context) {
    const onNewInput = (value: string) => {
      if (value.length > props.length) {
        return;
      }
      context.emit("update:modelValue", value);
    };

    const realInput = ref<HTMLInputElement | null>();
    const onPinInputClick = () => {
      console.log("clicked");
      if (realInput.value == null) {
        return;
      }
      realInput.value.click();
      realInput.value.focus();
    };
    return { onNewInput, realInput, onPinInputClick, range };
  },
});
