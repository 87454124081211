import { ref, computed } from "vue";
import { OrderItemRaw } from "@/models";
import { sumBy, map } from "lodash";
import { postOrderForm } from "@/requests";
import router from "@/router";
import { AuthToken } from "@/auth";

export function useCart(auth: AuthToken) {
  const cartItems = ref<OrderItemRaw[]>([]);
  const addToCart = (item: OrderItemRaw) => {
    cartItems.value = [...cartItems.value, item];
  };
  const readyToOrder = computed((): boolean => cartItems.value.length > 0);
  const totalPrice = computed((): string =>
    sumBy(cartItems.value, (item) =>
      parseFloat(item.menu_item.net_price)
    ).toFixed(2)
  );
  const cancel = () => {
    cartItems.value = [];
  };

  const removeItem = (index: number) => {
    cartItems.value = cartItems.value.filter((item, idx) => idx !== index);
  };
  const placeOrder = async () => {
    console.log("placing order", {
      ...JSON.parse(JSON.stringify(cartItems.value)),
    });
    try {
      const order = await postOrderForm(
        {
          items: map(cartItems.value, (i) => ({
            ...i,
            menu_item: i.menu_item.id,
            exclusive_extensions: map(i.exclusive_extensions, (e) => e.id),
            multiple_choice_extensions: map(
              i.multiple_choice_extensions,
              (e) => e.id
            ),
          })),
        },
        auth
      );

      cancel();
      router.push({ name: "order-status", params: { orderUuid: order.uuid } });
    } catch (e) {
      console.error("Redirecting to error page");
    }
  };
  return {
    cartItems,
    addToCart,
    readyToOrder,
    totalPrice,
    cancel,
    placeOrder,
    removeItem,
  };
}
