const AUTH_TOKEN_LOCAL_STORAGE_KEY = 'auth-token';
const KITCHEN_AUTH_TOKEN_LOCAL_STORAGE_KEY = 'kitchen-auth-token';

export class AuthToken {
    public constructor(private provider: typeof window.sessionStorage | typeof window.localStorage, private key: string) {} // eslint-disable-line
    public get token(): string | null {
        return this.provider.getItem(this.key);
    }
    public set token(value: string | null) {
        if (value === null) {
            this.provider.removeItem(this.key);
            return;
        }
        this.provider.setItem(this.key, value);
    }
}

export const userAuth = new AuthToken(window.sessionStorage, AUTH_TOKEN_LOCAL_STORAGE_KEY);
export const kitchenAuth = new AuthToken(window.localStorage, KITCHEN_AUTH_TOKEN_LOCAL_STORAGE_KEY);
