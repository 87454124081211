import { ref, onMounted, computed, SetupContext } from "vue";
import {
  MenuItem,
  OrderItemForm,
  ExtensionChoiceRaw,
  ExtensionRaw,
} from "@/models";
import { useLoading } from "@/mixins/common";
import { getMenuItems } from "@/requests";
import { uniqBy, includes, map, filter } from "lodash";
import { AuthToken } from "@/auth";

export function useMenuItems(auth: AuthToken) {
  const { loading, withLoading } = useLoading();
  const menuItems = ref<MenuItem[]>([]);
  const getData = () => {
    withLoading(async () => {
      menuItems.value = (await getMenuItems(auth)).results;
    });
  };
  onMounted(async () => {
    await getData();
  });
  return {
    menuLoading: loading,
    menuItems,
  };
}

export function useOrderItemBuilder(
  menuItem: MenuItem,
    context: SetupContext<("add-to-cart" | "cancel")[]>
) {
  const orderItem = ref<OrderItemForm | null>(null);
  const isActive = computed((): boolean => orderItem.value !== null);
  const initialize = () => {
    if (isActive.value) return;
    orderItem.value = {
      menu_item: menuItem.raw,
      exclusive_extensions: [],
      multiple_choice_extensions: [],
    };
  };

    const cancel = () => {
      orderItem.value = null;
      context.emit('cancel');
  };

  const onClickExclusiveExtensionSelection = (
    extensions: ExtensionChoiceRaw[]
  ) => {
      initialize();
      console.log({extensions});
    orderItem.value = {
      ...orderItem.value!,
      exclusive_extensions: [...extensions],
    };
  };

  const onClickMultipleChoiceExtensionSelection = (
    extensions: ExtensionChoiceRaw[]
  ) => {
    initialize();
    orderItem.value = {
      ...orderItem.value!,
      multiple_choice_extensions: [...extensions],
    };
  };

    const ok = () => {
    context.emit("add-to-cart", orderItem.value);
    orderItem.value = null;
  };

  const exclusiveExtensions = computed(
    (): ExtensionChoiceRaw[] => orderItem.value?.exclusive_extensions ?? []
  );
  const multipleChoiceExtensions = computed(
    (): ExtensionChoiceRaw[] =>
      orderItem.value?.multiple_choice_extensions ?? []
  );
  return {
    orderItem,
    isActive,
    initialize,
    cancel,
    onClickExclusiveExtensionSelection,
    onClickMultipleChoiceExtensionSelection,
    exclusiveExtensions,
    multipleChoiceExtensions,
    ok,
  };
}
