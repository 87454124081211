<template>
  <div class="Navbar">
    <!-- <blob-large class="blob-large" /> -->
    <zajedz-logo class="zajedz-logo" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
// import BlobLarge from "@/assets/graphics/zajedz-blob-large.svg";
import ZajedzLogo from "@/assets/graphics/zajedz-logo.svg";
export default defineComponent({
  name: "Navbar",
  components: {
    // BlobLarge,
    ZajedzLogo,
  },
  props: {},
  setup() {
    return {};
  },
});
</script>

<style scoped lang="scss">
@import "@/styles/main";
.Navbar {
  @include grid-center;
  @include app-gradient;
  z-index: 200;
  /* .blob-large {
       position: absolute;
       top: -24rem;
       left: -10rem;
       } */

  .zajedz-logo {
    transform: translateX(0.5rem);
    max-height: $navbar-height * 0.7;

    @include for-tablet-portrait-up {
      max-height: $large-navbar-height * 0.7;
      left: 5rem;
      top: 1rem;
      transform: scale(1.1);
    }
  }
}
</style>
