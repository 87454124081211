
import { defineComponent, onMounted, ref } from "vue";
import { useOrderTracking } from "@/mixins/order-tracking";
import OrderItemStatusBox from "@/components/order-status/OrderItemStatusBox.vue";
import { kitchenAuth } from "@/auth";

export default defineComponent({
  name: "MyOrderTrackingView",
  components: {
    OrderItemStatusBox,
  },
  props: {
    orderUuid: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const page = ref<HTMLDivElement | null>(null);
    onMounted(() => {
      setTimeout(() => {
        if (page.value == null || page.value.parentElement == null) {
          return;
        }
        page.value.parentElement.scrollBy({
          top: window.innerHeight,
          behavior: "smooth",
        });
      }, 1500);
    });
    return {
      page,
      ...useOrderTracking(props.orderUuid, kitchenAuth),
    };
  },
});
