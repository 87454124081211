
import {defineComponent, onMounted} from 'vue';
import router from '@/router';
export default defineComponent({
    name: 'SomethingWentWrong',
    props: {
        error: {
            type: String,
            default: '',
        }
    },
    setup() {
        onMounted(() => {
            setTimeout(() => {
                router.go(-3);
            }, 2400)
        })
        return {};
    },
});
