<template>
  <div class="KitchenOrderManagementView" :class="{ ordersLoading }">
    <div
      v-for="order of orders"
      :key="order.raw.uuid"
      class="order"
      :class="{ [order.status]: true }"
    >
      <div class="order-number" :class="{ [order.status]: true }">
        {{ order.raw.order_number }}
      </div>
      <div
        class="order-item"
        v-for="orderItem of order.orderItems"
        :key="orderItem.raw.id"
        :class="{ [orderItem.status]: true }"
      >
        <div class="item-date">złożono {{ orderItem.timeHumanSince }}</div>
        <div class="item-name">
          {{ orderItem.name }}
        </div>
        <div
          class="extension"
          v-for="(extension, index) of orderItem.extensions"
          :key="index"
        >
          {{ extension }}
        </div>
        <div
          class="item-set-status"
          v-for="(humanName, status) of ORDER_ITEM_STATUS_AVAILABLE_KITCHEN"
          :key="status"
          :class="{ [status]: true }"
          @click="setStatus(orderItem, status)"
        >
          {{ humanName }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useOrdersManagement } from "@/mixins/order-tracking";
import { useInternalTokenSetup, COMPONENT_TOKEN_PROPS } from "@/mixins/token";
import { ROUTE_NAMES } from "@/router";
import { kitchenAuth } from "@/auth";
export default defineComponent({
  name: "KitchenOrderManagementView",
  components: {},
  props: {
    ...COMPONENT_TOKEN_PROPS,
  },
  setup(props) {
    return {
      ...useOrdersManagement(kitchenAuth),
      ...useInternalTokenSetup(
        computed(() => props.token),
        ROUTE_NAMES["KitchenOrderManagementView"]
      ),
    };
  },
});
</script>

<style lang="scss">
@import "@/styles/main";
html * {
  max-width: unset !important;
}

$box-width: 18rem;
$box-height: 10rem;
$gap: 0.7rem;
.KitchenOrderManagementView {
  transition: all 0.2s ease-in;
  &.ordersLoading {
    filter: grayscale(40%);
  }
  @include grid-center;
  padding-top: 5rem;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-gap: $gap * 6;
  .order {
    @include grid-center;
    grid-template-columns: repeat(auto-fill, minmax($box-width, 1fr));
    grid-auto-rows: min-content;
    grid-gap: $gap;
    & > * {
      @include grid-center;
    }

    .order-item {
      @include app-card;
      @include status-background($force: true);
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: min-content;
      grid-gap: $gap;
      & > * {
        grid-column: 1 / -1;
      }
      align-content: start;
      .item-date {
        font-style: italic;
        font-size: 60%;
      }
      .item-name {
        font-weight: 900;
      }
      .extension {
        font-style: italic;
        font-size: 0.9rem;
        border-radius: 1rem;
      }

      .item-set-status {
        @include app-card;
        grid-column: unset;
        font-size: 70%;
        font-weight: 900;
        @include status-background;
        @include grid-center;
        height: 3rem;
        @include hoverable;
        border: 3px solid black;
      }
    }
    .order-number {
      @include status-circle($box-height * 0.9);
      border-color: lightgray;
      grid-column: 1;
      grid-row: 1 / -1 !important;
    }
  }
}
</style>
