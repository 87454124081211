<template>
  <div class="QRCodeScannedView">
    <h1>Wprowadź kod</h1>
    <pin-input
      name=""
      type="text"
      :modelValue="hashEnding"
      @update:modelValue="onNewPin($event)"
      :length="HASH_CODE_LENGTH"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useQRCodeScanned } from "@/mixins/qrcode";
import PinInput from "@/components/qr/PinInput.vue";
import { HASH_CODE_LENGTH } from "@/config";

export default defineComponent({
  name: "QRCodeScannedView",
  components: { PinInput },
  props: {
    initialHashEnding: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    console.log({ props: JSON.stringify(props) });
    return {
      ...useQRCodeScanned(props.initialHashEnding),
      HASH_CODE_LENGTH,
    };
  },
});
</script>

<style scoped lang="scss">
@import "@/styles/main";
.QRCodeScannedView {
  @include grid-center;
  grid-template-rows: 1fr 2fr;
  align-content: space-between;
}
</style>
