
import { defineComponent, ref } from "vue";
import ShoppingBag from "@/components/icons/ShoppingBag.vue";
import XCircle from "@/components/icons/XCircle.vue";
import { OrderItemRaw } from "@/models";

export default defineComponent({
  name: "TheCart",
  components: { ShoppingBag, XCircle },
  emits: ["cancel", "place-order", "remove-item"],
  props: {
    cartItems: {
      type: Array as () => OrderItemRaw[],
      required: true,
    },
    totalPrice: {
      type: Number,
      required: true,
    },
  },
  setup(props, context) {
    const clicked = ref<boolean>(false);
    const itemDescription = (item: OrderItemRaw): string =>
      `${[
        item.exclusive_extensions.map((e) => e.text).join(", "),
        item.multiple_choice_extensions.map((e) => e.text).join(", "),
      ].join(", ")}`;

    const removeItem = (index: number) => {
      context.emit("remove-item", index);
    };

    const payOnSite = () => context.emit("place-order");
    const cancel = () => context.emit("cancel");
    return { clicked, itemDescription, removeItem, payOnSite, cancel };
  },
});
