<template>
  <div class="OrderItemStatusBox">
    <div class="item-name">{{ orderItem.name }}</div>
    <div class="item-status" :class="{ [orderItem.status]: true }">
      {{ orderItem.statusHuman }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { OrderItem } from "../../models";

export default defineComponent({
  name: "OrderItemStatusBox",
  props: {
    orderItem: {
      type: Object as () => OrderItem,
      required: true,
    },
  },
  setup() {
    return {};
  },
});
</script>

<style scoped lang="scss">
@import "@/styles/main";
.OrderItemStatusBox {
  @include grid-center;
  @include app-card;
  grid-template-columns: 2fr 1fr;
  overflow: hidden;
  .item-status {
    @include grid-center;
    @include status-background;
    font-style: italic;
    font-weight: bolder;
    font-size: 60%;
  }
}
</style>
