import { ref, onMounted, onUnmounted } from "vue";
import { debounce } from "lodash";

export function sleep(ms: number): Promise<void> {
  return new Promise((complete) => {
    setTimeout(() => {
      complete();
    }, ms);
  });
}
export function useLoading() {
  const loading = ref<boolean>(false);
  const withLoading = async (fn: () => Promise<void>) => {
    loading.value = true;
    await fn();
    loading.value = false;
  };

  return {
    loading,
    withLoading,
  };
}

export function useInterval(func: () => Promise<void>, ms: number) {
  const poll = ref<number>(-1);
  onMounted(() => {
    poll.value = window.setInterval(debounce(func, 100), ms);
  });
  onUnmounted(() => {
    clearInterval(poll.value);
  });
  return {};
}
