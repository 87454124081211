<template>
  <div class="PinInput" @click="onPinInputClick()">
    <input
      name=""
      ref="realInput"
      type="text"
      :value="modelValue"
      @input="onNewInput($event.target.value)"
      class="real-input"
      :maxlength="length"
    />
    <div class="pin-box" v-for="index of range(length)" :key="index">
      {{ modelValue[index] ?? "" }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { range } from "lodash";

export default defineComponent({
  name: "PinInput",
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    length: {
      type: Number,
      required: true,
    },
  },
  setup(props, context) {
    const onNewInput = (value: string) => {
      if (value.length > props.length) {
        return;
      }
      context.emit("update:modelValue", value);
    };

    const realInput = ref<HTMLInputElement | null>();
    const onPinInputClick = () => {
      console.log("clicked");
      if (realInput.value == null) {
        return;
      }
      realInput.value.click();
      realInput.value.focus();
    };
    return { onNewInput, realInput, onPinInputClick, range };
  },
});
</script>

<style scoped lang="scss">
@import "@/styles/main";
$pin-box-size: 4rem;
.PinInput {
  @include grid-center;
  grid-auto-flow: column;
  grid-auto-columns: 2rem;
  grid-gap: 3rem;
  width: unset;
  height: min-content;
  .pin-box {
    @include grid-center;
    width: $pin-box-size;
    height: $pin-box-size;
    background-color: whitesmoke;
    border-radius: $pin-box-size * 0.1;
    color: $app-black;
    font-weight: 900;
    font-size: 180%;
    @include vuetify-shadow;
  }
  .real-input {
    position: absolute;
    transform: translateY(-5rem);
    opacity: 0;
    z-index: -1;
  }
}
</style>
