import { BACKEND_ADDRESS } from "@/config";
import {
  DjangoResponse,
  MenuItemRaw,
  MenuItem,
  OrderForm,
  OrderRaw,
  Order,
  OrderItemRaw,
} from "./models";
import { merge } from "lodash";
import { userAuth, kitchenAuth, AuthToken } from "@/auth";
import router from "@/router";
const API_ADDRESS = `${BACKEND_ADDRESS}api/`;

export type QueryParams = { [key: string]: string };

const FETCH_CONFIG: RequestInit = {
  headers: {
    "Content-Type": "application/json",
  },
};

const GET_CONFIG: RequestInit = {
  ...FETCH_CONFIG,
};

const POST_CONFIG: RequestInit = {
  ...FETCH_CONFIG,
  method: "POST",
};

const PATCH_CONFIG: RequestInit = {
  ...FETCH_CONFIG,
  method: "PATCH",
};

const authLazy = (auth: AuthToken) => ({
  headers: {
    Authorization: `Bearer ${auth.token}`,
  },
});

const getConfigLazy = (auth: AuthToken) => merge(GET_CONFIG, authLazy(auth));
const postConfigLazy = (auth: AuthToken) => merge(POST_CONFIG, authLazy(auth));
const patchConfigLazy = (auth: AuthToken) =>
  merge(PATCH_CONFIG, authLazy(auth));

export const show404 = (error?: string) => {
  router.push({ name: "404", params: { error: error ?? "" } });
};

function mapResponse<T, U>(
  response: DjangoResponse<T>,
  fn: (item: T) => U
): DjangoResponse<U> {
  return { ...response, results: response.results.map(fn) };
}

async function get<T>(
  address: string,
  auth: AuthToken,
  params: QueryParams = {}
): Promise<T> {
  const url = new URL(address);
  url.search = (new URLSearchParams(params) as unknown) as string;
  const response = await fetch(`${url}`, getConfigLazy(auth));
  if (!response.ok) {
    show404(await response.statusText);
    console.error(response.statusText);
  }
  try {
    return await response.json();
  } catch (e) {
    show404(e.toString());
    throw e;
  }
}

async function post<T>(
  address: string,
  body: object,
  auth: AuthToken
): Promise<T> {
  const response = await fetch(address, {
    ...postConfigLazy(auth),
    body: JSON.stringify(body),
  });
  if (!response.ok) {
    show404(response.statusText);
    throw new Error(response.statusText);
  }
  try {
    return await response.json();
  } catch (e) {
    show404(e.toString());
    throw new Error(response.statusText);
  }
}

async function patch<T>(
  address: string,
  body: object,
  auth: AuthToken
): Promise<T> {
  const response = await fetch(address, {
    ...patchConfigLazy(auth),
    body: JSON.stringify(body),
  });
  if (!response.ok) {
    show404(response.statusText);
    throw new Error(response.statusText);
  }
  try {
    return await response.json();
  } catch (e) {
    show404(e.toString());
    throw new Error(response.statusText);
  }
}
// MENU
// ----
const MENU_ITEM = `${API_ADDRESS}menu-items/`;
export async function getMenuItems(
  auth: AuthToken
): Promise<DjangoResponse<MenuItem>> {
  const response: DjangoResponse<MenuItemRaw> = await get(MENU_ITEM, auth);
  return mapResponse<MenuItemRaw, MenuItem>(
    response,
    (raw) => new MenuItem(raw)
  );
}

// ORDER
// -----
const ORDER = `${API_ADDRESS}orders/`;
export async function postOrderForm(
  orderForm: OrderForm,
  auth: AuthToken
): Promise<OrderRaw> {
  return await post(ORDER, orderForm, auth);
}

export async function getOrderStatus(
  uuid: string,
  auth: AuthToken
): Promise<Order | null> {
  return new Order(await get(`${ORDER}${uuid}/`, auth));
}

export async function getOrders(
  auth: AuthToken,
  params: QueryParams = {}
): Promise<Order[]> {
  return mapResponse(
    await get<DjangoResponse<OrderRaw>>(ORDER, auth, params),
    (r) => new Order(r)
  ).results;
}

// ORDER ITEM
// ----------
const ORDER_ITEM = `${API_ADDRESS}order-items/`;
export async function updateOrderItem(
  orderItemId: number,
  updated: Partial<OrderItemRaw>,
  auth: AuthToken
): Promise<OrderItemRaw> {
  return await patch(`${ORDER_ITEM}${orderItemId}/`, updated, auth);
}

// AUTH
// ----
const GET_ACCESS_TOKEN = `${API_ADDRESS}get-access-token/`;
export async function getAccessToken(
  hashEnding: string
): Promise<string | null> {
  try {
    return (
      await post<{ token: string }>(
        GET_ACCESS_TOKEN,
        {
          token_ending: hashEnding,
        },
        ({ token: "" } as any) as AuthToken
      )
    ).token; // eslint-disable-line
  } catch (e) {
    console.error(e.toString());
    return null;
  }
}
