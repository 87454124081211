
import { defineComponent } from "vue";
import { useMenuItems } from "@/mixins/menu-item";
import { useCart } from "@/mixins/cart";
import { userAuth } from "@/auth";
import MenuItemPizzaHut from "@/components/menu/MenuItemPizzaHut.vue";
import TheCart from "@/components/menu/TheCart.vue";
export default defineComponent({
  name: "Home",
  components: {
    MenuItemPizzaHut,
    TheCart,
  },
  setup() {
    return {
      ...useMenuItems(userAuth),
      ...useCart(userAuth),
    };
  },
});
